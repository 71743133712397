import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import { Container } from "../components/global"

import Footer from "../components/sections/footer"

import portalLicences from "../static/licences/portal-licences.json"

const PortalLicencesPage = () => (
  <Layout>
    <SEO title="Teacher Portal Licences" />
    <Navigation />
    <Container>
      <h1 style={{ marginTop: 120 }}>Teacher Portal Licences</h1>
      {Object.keys(portalLicences).map((key) => {
        return (
          <p>
            <a
              href={portalLicences[key].licenseUrl}
              rel="noopener noreferrer"
              style={{ color: "black", textDecoration: "none" }}
              target="_blank"
            >
              {key} ({portalLicences[key].licenses})
            </a>
          </p>
        )
      })}
    </Container>
    <Footer />
  </Layout>
)

export default PortalLicencesPage
